window.Salla.Tracking.Events = function () {
    return {
        init: function () {
            // init the helpers
            this.fire();
        },

        fire: function () {
            if (!Salla.Events || Salla.Events.length === 0) {
                return;
            }

            Object.keys(Salla.Events).forEach(function (event) {
                Salla.event.createAndDispatch(event, Salla.Events[event]);
            });
        },
    };
}();