window.Salla.Tracking.Links = function () {
    return {
        init: function () {
            // init the helpers
            this.menu();
        },

        menu: function () {
            // mixpanel.track_links('#nav a', 'click on menu link', {
            //     'referrer': document.referrer,
            // });
        },
    };
}();